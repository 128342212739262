import axiosInstance from '@/lib/api/client.ts';

export type AlertType = 'success' | 'info' | 'warning' | 'danger';

export type Alert = {
  type: AlertType;
  title?: string;
  message: string;
};

export const getAlerts = async () => {
  try {
    const { data } = await axiosInstance.get<Alert[]>('/alerts');
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
