import { useEffect, useState } from 'react';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button.tsx';
import { useQuery } from '@tanstack/react-query';
import { getAlerts } from '@/lib/alerts';

const defaultTitle = '¡Atención!';

export default function Alerts() {
  const [isOpened, setIsOpened] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const { data: alerts } = useQuery({
    queryKey: ['alerts'],
    queryFn: async () => {
      return await getAlerts();
    },
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (alerts?.length) {
      // Show the first alert that hasn't been dismissed and registered in session storage
      for (const alert of alerts) {
        const alertHash = JSON.stringify(alert);
        const alertId = `alert-${alertHash}`;

        const shouldShow = typeof sessionStorage !== 'undefined' && !sessionStorage.getItem(alertId);

        if (shouldShow) {
          setTitle(alert.title ?? defaultTitle);
          setDescription(alert.message);
          sessionStorage.setItem(alertId, new Date().toISOString());
          timeout = setTimeout(() => setIsOpened(true), 3000);
          break;
        }
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [alerts]);

  return (
    <Dialog open={isOpened} onOpenChange={setIsOpened}>
      <DialogContent>
        <DialogHeader>{!!title && <DialogTitle>{title}</DialogTitle>}</DialogHeader>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="outline-primary">
              Aceptar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
